import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Journal from '../views/Journal.vue'
import JournalContent from '../views/JournalContent.vue'
import Notice from '../views/Notice.vue'
import NoticeContent from '../views/NoticeContent.vue'
import PickupCard from '../views/PickupCard.vue'
import Calendar from '../views/Calendar.vue'
import HomeAddress from '../views/HomeAddress.vue'
import BankInfo from '../views/BankInfo.vue'
import Assessments from '../views/Assessments.vue'
import Student from '../views/Student.vue'
import StudentInfo from '../views/StudentInfo.vue'
import Course from '../views/Course.vue'
import Collection from '../views/collection/index.vue'
import CollChildList from '../views/collection/ChildList.vue'
import StepInfo from '../views/collection/StepInfo.vue'
import Completed from '../views/collection/Completed.vue'
import PTC from '../views/PTC.vue'
import PTCBooked from '../views/PTCBooked.vue'
import Subscribe from '../views/Subscribe.vue'
import SuccessAll from '../views/collection/SuccessAll.vue'
import More from '../views/More.vue'
import ContactCampus from '../views/ContactCampus.vue'
import ContactMessage from '../views/ContactMessage.vue'
import HandBook from '../views/HandBook.vue'
import Lunch from '../views/Lunch.vue'
import BillHistory from '../views/BillHistory.vue'
import AccountBalance from '../views/AccountBalance.vue'
import SuccessFeedback from '../views/SuccessFeedback.vue'
import JournalSearch from '../views/JournalSearch.vue'
import NoticeSearch from '../views/NoticeSearch.vue'
import Uniform from '../views/uniform.vue'
import UniformList from '../views/uniformList.vue'
import UniformDetail from '../views/uniformDetail.vue'
import UniformShop from '../views/uniformShop.vue'
import UnifromOrder from '../views/unifromOrder.vue'
import OrderDetail from '../views/orderDetail.vue'
import UniformConfirm from '../views/uniformConfirm.vue'
import UniformChoose from '../views/uniformChoose.vue'
import SPAC from '../views/Spac.vue'
import StuOnlineEs from '../views/stuOnlineEs.vue'
import StuOnlineSs from '../views/stuOnlineSs.vue'
import SurveyIndex from '../views/SurveyIndex.vue'
import SurveyStep from '../views/SurveyStep.vue'
import SurveyCompleted from '../views/SurveyCompleted.vue'
import Withdrawal from '../views/Withdrawal.vue'
import WithdrawalBill from '../views/WithdrawalBill.vue'
import Phoenix from '../views/Phoenix.vue'
import SchoolTeam from '../views/SchoolTeam.vue'
import SchoolTeamsHome from '../views/SchoolTeamsHome.vue'
import SchoolTeamsHomePreview from '../views/SchoolTeamsHomePreview.vue'
import ParentSchedule from '../views/ParentSchedule.vue'
import ParentScheduleDate from '../views/ParentScheduleDate.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'login'
    }
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'home'
    }
  },
  {
    path: '/journal/list/:childid/:school_id/:start_year/:week_num',
    name: 'Journal',
    component: Journal,
    meta: {
      title: 'journal'
    }
  },
  {
    path: '/journal/:id',
    name: 'JournalContent',
    component: JournalContent,
    meta: {
      title: 'journal_detail'
    }
  },
  {
    path: '/preview/journal/:id',
    name: 'PreviewJournalContent',
    component: JournalContent,
    meta: {
      title: 'journal_preview'
    }
  },
  {
    path: '/notice',
    name: 'Notice',
    component: Notice,
    meta: {
      title: 'notice',
      keepAlive: true
    }
  },
  {
    path: '/notice/:id',
    name: 'NoticeContent',
    component: NoticeContent,
    meta: {
      title: 'notice_detail'
    }
  },
  {
    path: '/preview/notice',
    name: 'PreviewNotice',
    component: Notice,
    meta: {
      title: 'notice_preview'
    }
  },
  {
    path: '/preview/notice/:id',
    name: 'PreviewNoticeContent',
    component: NoticeContent,
    meta: {
      title: 'notice_preview'
    }
  },
  {
    path: '/pickupCard',
    name: 'PickupCard',
    component: PickupCard,
    meta: {
      title: 'pickupCard'
    }
  },
  {
    path: '/bill/history',
    name: 'BillHistory',
    component: BillHistory,
    meta: {
      title: 'billHistory'
    }
  },
  {
    path: '/accountBalance',
    name: 'AccountBalance',
    component: AccountBalance,
    meta: {
      title: 'accountBalance'
    }
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: Calendar,
    meta: {
      title: 'calendar'
    }
  },
  {
    path: '/preview/calendar/:school_id/:yid',
    name: 'calendarView',
    component: Calendar,
    meta: {
      title: 'calendar_preview'
    }
  },
  {
    path: '/homeAddress',
    name: 'HomeAddress',
    component: HomeAddress,
    meta: {
      title: 'homeAddress'
    }
  },
  {
    path: '/bankInfo',
    name: 'BankInfo',
    component: BankInfo,
    meta: {
      title: 'bankInfo'
    }
  },
  {
    path: '/assessments',
    name: 'Assessments',
    component: Assessments,
    meta: {
      title: 'assessments'
    }
  },
  {
    path: '/student',
    name: 'Student',
    component: Student,
    meta: {
      title: 'student',
      // keepAlive: true
    }
  },
  {
    path: '/studentInfo/:id',
    name: 'StudentInfo',
    component: StudentInfo,
    meta: {
      title: 'studentinfo'
    }
  },
  {
    path: '/course',
    name: 'Course',
    component: Course,
    meta: {
      title: 'course'
    }
  },
  {
    path: '/preview/course/:id/:school_id/:sign',
    name: 'PreviewCourse',
    component: Course,
    meta: {
      title: 'course'
    }
  },
  {
    path: '/successFeedback/:type/:id',
    name: 'SuccessFeedback',
    component: SuccessFeedback,
    meta: {
      title: 'successFeedback'
    }
  },
  {
    path: '/collection/index/:id',
    name: 'Collection',
    component: Collection,
    meta: {
      title: 'collection'
    }
  },
  {
    path: '/collection/childList/:id',
    name: 'CollChildList',
    component: CollChildList,
    meta: {
      title: 'collection'
    }
  },
  {
    path: '/collection/completed/:id/:childid',
    name: 'Completed',
    component: Completed,
    meta: {
      title: 'collection'
    }
  },
  {
    path: '/collection/success/:id/:childid',
    name: 'SuccessAll',
    component: SuccessAll,
    meta: {
      title: 'collection'
    }
  },
  {
    path: '/collection/stepInfo/:id/:stepName/:childid',
    name: 'StepInfo',
    component: StepInfo,
    meta: {
      title: 'collection'
    }
  },
  {
    path: '/ptc',
    name: 'PTC',
    component: PTC,
    meta: {
      title: 'ptc'
    }
  },
  {
    path: '/ptcBooked/:id/:year',
    name: 'PTCBooked',
    component: PTCBooked,
    meta: {
      title: 'ptcBooked'
    }
  },
  {
    path: '/subscribe',
    name: 'Subscribe',
    component: Subscribe,
    meta: {
      title: 'subscribe'
    }
  },
  {
    path: '/athletics2',
    name: 'Phoenix',
    component: Phoenix,
    meta: {
      title: 'phoenix'
    }
  },
  {
    path: '/competitions',
    beforeEnter() { location.href = 'https://sway.office.com/MUe7i4JDlhyTBjpY?ref=Link' }
  },
  {
    path: '/more',
    name: 'More',
    component: More,
    meta: {
      title: 'more'
    }
  },
  {
    path: '/message',
    name: 'ContactCampus',
    component: ContactCampus,
    meta: {
      title: 'contactCampus'
    }
  },
  {
    path: '/message/:id/:type/:childId',
    name: 'ContactMessage',
    component: ContactMessage,
    meta: {
      title: 'contactMessage'
    }
  },
  {
    path: '/handbook',
    name: 'HandBook',
    component: HandBook,
    meta: {
      title: 'handBook'
    }
  },
  {
    path: '/lunch',
    name: 'Lunch',
    component: Lunch,
    meta: {
      title: 'lunch'
    }
  },
  {
    path: '/journalsearch',
    name: 'JournalSearch',
    component: JournalSearch,
    meta: {
      title: 'journal_search'
    }
  },
  {
    path: '/noticesearch',
    name: 'NoticeSearch',
    component: NoticeSearch,
    meta: {
      title: 'notice',
      keepAlive: true
    }
  },
  {
    path: '/uniform',
    name: 'Uniform',
    component: Uniform,
    meta: {
      title: 'uniform'
    }
  },
  {
    path: '/uniformList/:id',
    name: 'UniformList',
    component: UniformList,
    meta: {
      title: 'uniform'
    }
  },
  {
    path: '/uniformDetail/:id',
    name: 'UniformDetail',
    component: UniformDetail,
    meta: {
      title: 'uniform'
    }
  },
  {
    path: '/uniformShop',
    name: 'UniformShop',
    component: UniformShop,
    meta: {
      title: 'uniform'
    }
  },
  {
    path: '/unifromOrder',
    name: 'UnifromOrder',
    component: UnifromOrder,
    meta: {
      title: 'uniform'
    }
  },
  {
    path: '/orderDetail/:id',
    name: 'OrderDetail',
    component: OrderDetail,
    meta: {
      title: 'uniform'
    }
  },
  {
    path: '/uniformConfirm',
    name: 'UniformConfirm',
    component: UniformConfirm,
    meta: {
      title: 'uniform'
    }
  },
  {
    path: '/uniformChoose/:id',
    name: 'UniformChoose',
    component: UniformChoose,
    meta: {
      title: 'uniform'
    }
  },
  {
    path: '/spac',
    name: 'SPAC',
    component: SPAC,
    meta: {
      title: 'spac'
    }
  },
  {
    path: '/stuOnlineSs',
    name: 'StuOnlineSs',
    component: StuOnlineSs,
    meta: {
      title: 'ss'
    }
  },
  {
    path: '/stuOnlineEs',
    name: 'StuOnlineEs',
    component: StuOnlineEs,
    meta: {
      title: 'es'
    }
  },
  {
    path: '/surveyIndex',
    name: 'SurveyIndex',
    component: SurveyIndex,
    meta: {
      title: 'survey'
    }
  },
  {
    path: '/surveyStep',
    name: 'SurveyStep',
    component: SurveyStep,
    meta: {
      title: 'survey'
    }
  },
  {
    path: '/surveyCompleted',
    name: 'SurveyCompleted',
    component: SurveyCompleted,
    meta: {
      title: 'survey'
    }
  },
  {
    path: '/withdrawal/:id',
    name: 'Withdrawal',
    component: Withdrawal,
    meta: {
      title: 'withdrawal'
    }
  },
  {
    path: '/preview/withdrawal/:id',
    name: 'previewWithdrawal',
    component: Withdrawal,
    meta: {
      title: 'previewWithdrawal'
    }
  },
  {
    path: '/withdrawalBill/:id',
    name: 'WithdrawalBill',
    component: WithdrawalBill,
    meta: {
      title: 'withdrawalBill'
    }
  },
  {
    path: '/preview/withdrawalBill/:id',
    name: 'previewWithdrawalBill',
    component: WithdrawalBill,
    meta: {
      title: 'previewWithdrawalBill'
    }
  },
  {
    path: '/preview/schoolTeams/:id',
    name: 'previewSchoolTeams',
    component: SchoolTeam,
    meta: {
      title: 'previewSchoolTeams'
    }
  },
  {
    path: '/schoolTeams/:id',
    name: 'SchoolTeams',
    component: SchoolTeam,
    meta: {
      title: 'Phoenix'
    }
  },
  {
    path: '/athletics',
    name: 'SchoolTeamsHome',
    component: SchoolTeamsHome,
    meta: {
      title: 'Phoenix'
    }
  },
  {
    path: '/preview/schoolTeamsHome/:id',
    name: 'previewSchoolTeamsHome',
    component: SchoolTeamsHomePreview,
    meta: {
      title: 'previewSchoolTeamsHome'
    }
  },
  {
    path: '/parentSchedule/:id',
    name: 'ParentSchedule',
    component: ParentSchedule,
    meta: {
      title: 'parentSchedule'
    }
  },
  {
    path: '/parentScheduleDate/:id/:childid',
    name: 'ParentScheduleDate',
    component: ParentScheduleDate,
    meta: {
      title: 'parentSchedule'
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
