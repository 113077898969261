<template>
  <div>
    <div class="children-wrap">
      <van-row type="flex" justify="space-between" align="center">
        <van-col>
          <div v-for="(child, i) in childData" :key="i" class="avatar-box fl mr-10" @click="showSwitch">
            <van-image
              round
              :src="child.avatar"
              :class="{'active': child.id==current.id}"
              class="avatar-1" />
              <van-icon name="checked" color="#4D88D2" class="icon-1" v-if="child.id==current.id" />
            </div>
        </van-col>
        <van-col v-if="columns.length>0">
          <div class="schoolYear" @click="ActionShow = true" v-if='currentYear.label'>{{replace(currentYear.label)}}
              <van-icon name="arrow-up" class='open' v-if="ActionShow" />
              <van-icon name="arrow-down" class='open' v-if="!ActionShow" />
          </div>
        </van-col>
      </van-row>
    </div>
    <van-action-sheet
      v-model="show"
      :cancel-text="$t('global.cancel')"
      close-on-click-action>
        <van-row type="flex" justify="space-between" align="center" class="van-action-sheet__item"
        v-for="(child, i) in childData" :key="i"
        @click="switchChild(child)">
          <van-col>
            <van-image
              round
              :src="child.avatar"
              class="avatar-1 mr-10" />
            {{ child.name }}
          </van-col>
          <van-col>
            <van-icon name="success" color="#4E89D3" v-if="child.id==current.id" />
          </van-col>
        </van-row>
    </van-action-sheet>
    <van-action-sheet v-model="ActionShow">
      <div class="content">
          <van-picker
          show-toolbar
          :default-index="selectIndex"
          value-key="label"
          :columns="columns"
          @confirm="onConfirm"
          @cancel="onCancel"
          />
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import Vue from 'vue';
import { Col, Row, Image as VanImage, ActionSheet } from 'vant';
import storage from 'store'
import { CURRENT_CHILD } from '@/store/mutation-types'
import { childList } from '@/api/child'

Vue.use(Col);
Vue.use(Row);
Vue.use(VanImage);
Vue.use(ActionSheet);

export default {
  name: 'Children',
  props: {
    children: Array,
    startYear:Array
  },
  data() {
    return {
      childData: [],
      current: {},
      show: false,
      ActionShow:false,
      columns: [],
      currentYear:{},
      selectIndex:null
    }
  },
  watch: {
    children(val) {
      this.childData = val
    },
    startYear(val,old) {
      this.columns = val
      if(val.length!=old.length){
        this.currentYear={}
        this.selectIndex=0
      }
      this.startYearId()
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    fetchData() {
      if (this.children) {
        this.childData = this.children
        this.handChild()
      }
      else {
        childList().then(res => {
          this.childData = res.data
          this.handChild()
        })
      }
    },
    showSwitch() {
      if (this.childData.length>1) {
        this.show = true
      }
    },
    handChild() {
      let hasChild = false
      let queryChild = -1
      let cChild = storage.get(CURRENT_CHILD) || this.childData[0]
      this.childData.map((item, index) => {
        if (cChild.id == item.id) {
          hasChild = true
        }
        if (this.$route.query.childid == item.id) {
          queryChild = index
        }
      })
      if (queryChild > -1) {
        this.current = this.childData[queryChild]
      }
      else if (hasChild) {
        this.current = cChild
      }
      else {
        this.current = this.childData[0]
      }
      this.switchChild(this.current)
    },
    switchChild(obj) {
      this.current = obj;
      storage.set(CURRENT_CHILD, this.current)
      this.$emit('change', this.current)
      this.show = false;
    },
    startYearId(){
      if(this.columns.length>0){
        if(Object.keys(this.currentYear).length == 0){
          this.selectIndex=0
          this.currentYear=this.columns[0]
          this.onConfirm(this.currentYear, 0)
          
        }else{
          this.columns.map((item, index) => {
            if (this.currentYear.value == item.value) {
              this.selectIndex=index
            }
          })
        }
      }
    },
    replace(str){
      return str.replace(" School Year", "");
    },
    onConfirm(value, index) {
      this.currentYear=value
      this.selectIndex=index
      this.$emit('click', index)
      this.ActionShow = false;
    },
    onCancel(){
      this.ActionShow = false;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.children-wrap {
  padding: 0 15px;
  height: 52px;
  line-height: 52px;
  background: #FFFFFF;
  .active {
    border: 2px solid #4D88D2;
  }
  .avatar-box {
    width: 36px;
    position: relative;
    .van-icon {
      position: absolute;
      bottom: 6px;
      right: -7px;
    }
  }
}
.avatar-1 {
  vertical-align: middle;
  width: 30px;
  height: 30px;
}
.icon-1::before {
  font-size: 12px;
}
.van-action-sheet__item {
  display: flex;
  width: auto;
  text-align: left;
  border-bottom: 1px solid #EBEDF0;
  font-weight: 400;
  color: #323233;
}
.van-action-sheet__item:last-child {
 border-bottom: 0;
}
.schoolYear{
    line-height: 52px;
    padding-right:20px;
    position: relative;
    .open{
        position: absolute;
        right: 0;
        top: 18px;
    }
}
</style>
